import { Component, ViewChild, ElementRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { fabric } from 'fabric';
import { ImageService } from 'src/app/image.service';
import GIF from 'gif.js.optimized';
import { parseGIF, decompressFrames } from 'gifuct-js';
import { WebpMachine } from 'webp-hero';



@Component({
  selector: 'angular-editor-fabric-js',
  templateUrl: './angular-editor-fabric-js.component.html',
  styleUrls: ['./angular-editor-fabric-js.component.css'],
})
export class FabricjsEditorComponent implements AfterViewInit {
  @ViewChild('htmlCanvas') htmlCanvas: ElementRef;

  private canvas: fabric.Canvas;
  public props = {
    canvasFill: '#ffffff',
    canvasImage: '',
    id: null,
    opacity: null,
    fill: null,
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: null,
    TextDecoration: ''
  };

  public textString: string;
  public url: string | ArrayBuffer = '';
  public size: any = {
    width: 835,
    height: 800
  };

  public json: any;
  private globalEditor = false;
  public textEditor = false;
  private imageEditor = false;
  public figureEditor = false;
  public selected: any;
  public backgroundImage: string | ArrayBuffer = '';

  public images_template: string[] = [];  // Store image URLs
  public images_overlay: string[] = [];  // Store image URLs
  @Output() imagesTemplateUpdated = new EventEmitter<string[]>();
  @Output() imagesOverlayUpdated = new EventEmitter<string[]>();
  @Output() recordingCompleteUpdated = new EventEmitter<boolean>();


  constructor(private imageService: ImageService) {}  // Inject the ImageService

  public frames = [];
  private currentFrame = 0;
  private frameInterval;
  public defaultDelay = 100; // Default frame delay in ms
  public frameCanvases: fabric.Canvas[] = [];
  public savedObjects: any[] = [];
  public startFrame = 0;
  public endFrame = 0;
  public isRecording = false;
  public currentGifObject: any = null;
  public isManualEditing = false;


  ngAfterViewInit(): void {
    const isMobile = window.innerWidth <= 768; // Adjust this value based on your needs

    // setup front side canvas
    this.canvas = new fabric.Canvas(this.htmlCanvas.nativeElement, {
      hoverCursor: 'pointer',
      selection: !isMobile,
      selectionBorderColor: 'blue',
      //isDrawingMode: true //No drawing as default
    });

    this.canvas.on({
      'object:moving': (e) => { },
      'object:modified': (e) => { },
      'object:selected': (e) => {

        const selectedObject = e.target;
        this.selected = selectedObject;
        selectedObject.hasRotatingPoint = true;
        selectedObject.transparentCorners = false;
        selectedObject.cornerColor = 'rgba(255, 87, 34, 0.7)';

        this.resetPanels();

        if (selectedObject.type !== 'group' && selectedObject) {

          this.getId();
          this.getOpacity();

          switch (selectedObject.type) {
            case 'rect':
            case 'circle':
            case 'triangle':
              this.figureEditor = true;
              this.getFill();
              break;
            case 'i-text':
              this.textEditor = true;
              this.getLineHeight();
              this.getCharSpacing();
              this.getBold();
              this.getFill();
              this.getTextDecoration();
              this.getTextAlign();
              this.getFontFamily();
              break;
            case 'image':
              break;
          }
        }
      },
      'selection:cleared': (e) => {
        this.selected = null;
        this.resetPanels();
      }
    });

    this.canvas.setWidth(this.size.width);
    this.canvas.setHeight(this.size.height);
    this.adjustCanvasSize();

    // get references to the html canvas element & its context
    this.canvas.on('mouse:down', (e) => {
      const canvasElement: any = document.getElementById('canvas');
    });

    this.loadImagesTemplate('template');
    this.loadImagesOverlay('imageoverlay');


  }


  /*------------------------Block elements------------------------*/

  // Block "Size"

  adjustCanvasSize() {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) { // Common breakpoint for mobile devices
      this.size.width = 200; // Subtracting margin/padding if any
      this.size.height = 200; // Maintain aspect ratio or adjust as needed
    }
  }

  changeSize() {
    // Get the current canvas dimensions
    const currentWidth = this.canvas.getWidth();
    const currentHeight = this.canvas.getHeight();
    
    // Calculate the scale factors for width and height
    const widthScaleFactor = this.size.width / currentWidth;
    const heightScaleFactor = this.size.height / currentHeight;
    
    // Resize the canvas to the new dimensions
    this.canvas.setWidth(this.size.width);
    this.canvas.setHeight(this.size.height);
    
    // Scale all objects on the canvas to maintain their relative positions and sizes
    this.canvas.getObjects().forEach((obj) => {
      obj.scaleX *= widthScaleFactor;
      obj.scaleY *= heightScaleFactor;
      obj.setCoords(); // Update object coordinates after scaling
    });
    
    // Scale the background image if it exists
    if (this.canvas.backgroundImage) {
      const backgroundImage = this.canvas.backgroundImage as fabric.Image;
      backgroundImage.scaleToWidth(this.size.width); // Scale image to match canvas width
      backgroundImage.scaleToHeight(this.size.height); // Scale image to match canvas height
    }
    
    // Render the canvas to reflect the changes
    this.canvas.renderAll();
  }
  
  
  
  

  // Block "Add text"

  addText() {
    if (this.textString) {
      const text = new fabric.IText(this.textString, {
        left: 10,
        top: 10,
        fontFamily: 'arial',
        angle: 0,
        fill: 'white',
        stroke: 'black',
        strokeWidth: 2,
        scaleX: 0.5,
        scaleY: 0.5,
        fontWeight: '',
        hasRotatingPoint: true
      });

      this.extend(text, this.randomId());
      this.canvas.add(text);
      this.selectItemAfterAdded(text);
      this.textString = '';
    }
  }

  addMemeText() {
    const canvasWidth = this.canvas.getWidth();
    const topText = new fabric.IText('Top Text', {
        left: canvasWidth / 2,
        top: 10,
        fontFamily: 'Arial',
        fontSize: 93,
        textAlign: 'center',
        fill: 'white',
        stroke: 'black',
        strokeWidth: 2,
        originX: 'center'
    });

    const bottomText = new fabric.IText('Bottom Text', {
        left: canvasWidth / 2,
        top: this.canvas.getHeight() - 100,
        fontFamily: 'Arial',
        fontSize: 93,
        textAlign: 'center',
        fill: 'white',
        stroke: 'black',
        strokeWidth: 2,
        originX: 'center'
    });

    this.canvas.add(topText, bottomText);
    this.canvas.renderAll();
}


  // Block "Add images"

  getImgPolaroid(event: any) {
    const el = event.target;
    fabric.loadSVGFromURL(el.src, (objects, options) => {
      const image = fabric.util.groupSVGElements(objects, options);
      image.set({
        left: 10,
        top: 10,
        angle: 0,
        padding: 10,
        cornerSize: 10,
        hasRotatingPoint: true,
      });
      this.extend(image, this.randomId());
      this.canvas.add(image);
      this.selectItemAfterAdded(image);
    });
  }

  // Block "Load Images"

  loadImagesTemplate(containerName: string): void {
    this.imageService.getImageList(containerName).subscribe({
      next: (images) => {
        this.images_template = images;  // Assuming the server responds with an array of URLs
        this.imagesTemplateUpdated.emit(this.images_template);
        console.log('Loaded images:', images);
      },
      error: (err) => console.error('Error loading images:', err)
    });
  }

  loadImagesOverlay(containerName: string): void {
    this.imageService.getImageList(containerName).subscribe({
      next: (images) => {
        this.images_overlay = images;  // Assuming the server responds with an array of URLs
        this.imagesOverlayUpdated.emit(this.images_overlay);
        console.log('Loaded images:', images);
      },
      error: (err) => console.error('Error loading images:', err)
    });
  }

  // Block "Upload Image"

  addImageOnCanvas(url) {
  
    // Check if the URL points to a GIF
    if (url.toLowerCase().endsWith('.gif') || url.toLowerCase().startsWith('data:image/gif;base64,')) {
      // For GIFs, load the frames and start animation, with optional frame range
      this.addGifOnCanvas(url);
    } else {
      fabric.Image.fromURL(url, (image) => {
        image.set({
          left: 10,
          top: 10,
          angle: 0,
          padding: 10,
          cornerSize: 10,
          hasRotatingPoint: true
        });
        image.scaleToWidth(200);
        image.scaleToHeight(200);
        this.extend(image, this.randomId());
        this.canvas.add(image);
        this.selectItemAfterAdded(image);
      }, { crossOrigin: 'anonymous' });
    }
  }

  readUrl(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        this.url = readerEvent.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  removeWhite(url) {
    this.url = '';
  }

  // Block "Add figure"

  addFigure(figure) {
    let add: any;
    switch (figure) {
      case 'rectangle':
        add = new fabric.Rect({
          width: 200, height: 100, left: 10, top: 10, angle: 0,
          fill: '#000000'
        });
        break;
      case 'square':
        add = new fabric.Rect({
          width: 100, height: 100, left: 10, top: 10, angle: 0,
          fill: '#000000'
        });
        break;
      case 'triangle':
        add = new fabric.Triangle({
          width: 100, height: 100, left: 10, top: 10, fill: '#000000'
        });
        break;
      case 'circle':
        add = new fabric.Circle({
          radius: 50, left: 10, top: 10, fill: '#000000'
        });
        break;
    }
    this.extend(add, this.randomId());
    this.canvas.add(add);
    this.selectItemAfterAdded(add);
  }

  changeFigureColor(color) {
    this.canvas.getActiveObject().set("fill", color);
    this.canvas.renderAll();
  };

  /*Canvas*/

  cleanSelect() {
    this.canvas.discardActiveObject().renderAll();
  }

  selectItemAfterAdded(obj) {
    this.canvas.discardActiveObject().renderAll();
    this.canvas.setActiveObject(obj);
  }

  setCanvasFill() {
    if (!this.props.canvasImage) {
      this.canvas.backgroundColor = this.props.canvasFill;
      this.canvas.renderAll();
    }
  }

  // Method to read and set the background image from an upload
uploadBackgroundImage(event: Event): void {
  const file = (event.target as HTMLInputElement).files?.[0];
  if (file) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
          this.backgroundImage = event.target.result;
          console.log(this.backgroundImage);
          this.setCanvasBackground(this.backgroundImage as string);
      };
      reader.readAsDataURL(file);
  }
}

setCanvasBackground(url: string, startFrame: number = 0, endFrame: number = null): void {
  if (this.frameInterval) {
    clearInterval(this.frameInterval);
    this.frameInterval = null; // Clear the interval ID
  }

  this.canvas.clear();
  this.currentFrame = 0;  // Optionally reset the currentFrame index

  // Check if the URL points to a GIF
  // if (url.toLowerCase().endsWith('.gif') || url.toLowerCase().startsWith('data:image/gif;base64,')) {
  //   // For GIFs, load the frames and start animation, with optional frame range
  //   this.loadGifFrames(url);
  if (url.toLowerCase().endsWith('.gif') || url.toLowerCase().startsWith('data:image/gif;base64,')) {
    // For GIFs or animated WEBPs, load the frames and start animation, with optional frame range
    this.loadGifFrames(url);
  } else {
    // For images, set the canvas background
    fabric.Image.fromURL(url, (img) => {
      this.size.width = img.width;
      this.size.height = img.height;
      this.canvas.setWidth(img.width);
      this.canvas.setHeight(img.height);

      this.canvas.setBackgroundImage(img, () => {
        this.canvas.renderAll();
      }, {
        scaleX: 1,
        scaleY: 1,
        originX: 'left',
        originY: 'top'
      });
    }, { crossOrigin: 'anonymous' });
  }
}

  extend(obj, id) {
    obj.toObject = ((toObject) => {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          id
        });
      };
    })(obj.toObject);
  }

  setCanvasImage() {
    const self = this;
    if (this.props.canvasImage) {
      this.canvas.setBackgroundColor(new fabric.Pattern({ source: this.props.canvasImage, repeat: 'repeat' }), () => {
        self.props.canvasFill = '';
        self.canvas.renderAll();
      });
    }
  }

  randomId() {
    return Math.floor(Math.random() * 999999) + 1;
  }




  /*------------------------Global actions for element------------------------*/

  getActiveStyle(styleName, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) { return ''; }

    if (object.getSelectionStyles && object.isEditing) {
      return (object.getSelectionStyles()[styleName] || '');
    } else {
      return (object[styleName] || '');
    }
  }

  setActiveStyle(styleName, value: string | number, object: fabric.IText) {
    object = object || this.canvas.getActiveObject() as fabric.IText;
    if (!object) { return; }

    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      style[styleName] = value;

      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.setSelectionStyles({ underline: true });
        } else {
          object.setSelectionStyles({ underline: false });
        }

        if (value.includes('overline')) {
          object.setSelectionStyles({ overline: true });
        } else {
          object.setSelectionStyles({ overline: false });
        }

        if (value.includes('line-through')) {
          object.setSelectionStyles({ linethrough: true });
        } else {
          object.setSelectionStyles({ linethrough: false });
        }
      }

      object.setSelectionStyles(style);
      object.setCoords();

    } else {
      if (typeof value === 'string') {
        if (value.includes('underline')) {
          object.set('underline', true);
        } else {
          object.set('underline', false);
        }

        if (value.includes('overline')) {
          object.set('overline', true);
        } else {
          object.set('overline', false);
        }

        if (value.includes('line-through')) {
          object.set('linethrough', true);
        } else {
          object.set('linethrough', false);
        }
      }

      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.renderAll();
  }


  getActiveProp(name) {
    const object = this.canvas.getActiveObject();
    if (!object) { return ''; }

    return object[name] || '';
  }

  setActiveProp(name, value) {
    const object = this.canvas.getActiveObject();
    if (!object) { return; }
    object.set(name, value).setCoords();
    this.canvas.renderAll();
  }

  clone() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      let clone;
      switch (activeObject.type) {
        case 'rect':
          clone = new fabric.Rect(activeObject.toObject());
          break;
        case 'circle':
          clone = new fabric.Circle(activeObject.toObject());
          break;
        case 'triangle':
          clone = new fabric.Triangle(activeObject.toObject());
          break;
        case 'i-text':
          clone = new fabric.IText('', activeObject.toObject());
          break;
        case 'image':
          clone = fabric.util.object.clone(activeObject);
          break;
      }
      if (clone) {
        clone.set({ left: 10, top: 10 });
        this.canvas.add(clone);
        this.selectItemAfterAdded(clone);
      }
    }
  }

  getId() {
    this.props.id = this.canvas.getActiveObject().toObject().id;
  }

  setId() {
    const val = this.props.id;
    const complete = this.canvas.getActiveObject().toObject();
    console.log(complete);
    this.canvas.getActiveObject().toObject = () => {
      complete.id = val;
      return complete;
    };
  }

  getOpacity() {
    this.props.opacity = this.getActiveStyle('opacity', null) * 100;
  }

  setOpacity() {
    this.setActiveStyle('opacity', parseInt(this.props.opacity, 10) / 100, null);
  }

  getFill() {
    this.props.fill = this.getActiveStyle('fill', null);
  }

  setFill() {
    this.setActiveStyle('fill', this.props.fill, null);
  }

  getLineHeight() {
    this.props.lineHeight = this.getActiveStyle('lineHeight', null);
  }

  setLineHeight() {
    this.setActiveStyle('lineHeight', parseFloat(this.props.lineHeight), null);
  }

  getCharSpacing() {
    this.props.charSpacing = this.getActiveStyle('charSpacing', null);
  }

  setCharSpacing() {
    this.setActiveStyle('charSpacing', this.props.charSpacing, null);
  }

  getFontSize() {
    this.props.fontSize = this.getActiveStyle('fontSize', null);
  }

  setFontSize() {
    this.setActiveStyle('fontSize', parseInt(this.props.fontSize, 10), null);
  }

  getBold() {
    this.props.fontWeight = this.getActiveStyle('fontWeight', null);
  }

  setBold() {
    this.props.fontWeight = !this.props.fontWeight;
    this.setActiveStyle('fontWeight', this.props.fontWeight ? 'bold' : '', null);
  }

  setFontStyle() {
    this.props.fontStyle = !this.props.fontStyle;
    if (this.props.fontStyle) {
      this.setActiveStyle('fontStyle', 'italic', null);
    } else {
      this.setActiveStyle('fontStyle', 'normal', null);
    }
  }

  getTextDecoration() {
    this.props.TextDecoration = this.getActiveStyle('textDecoration', null);
  }

  setTextDecoration(value) {
    let iclass = this.props.TextDecoration;
    if (iclass.includes(value)) {
      iclass = iclass.replace(RegExp(value, 'g'), '');
    } else {
      iclass += ` ${value}`;
    }
    this.props.TextDecoration = iclass;
    this.setActiveStyle('textDecoration', this.props.TextDecoration, null);
  }

  hasTextDecoration(value) {
    return this.props.TextDecoration.includes(value);
  }

  getTextAlign() {
    this.props.textAlign = this.getActiveProp('textAlign');
  }

  setTextAlign(value) {
    this.props.textAlign = value;
    this.setActiveProp('textAlign', this.props.textAlign);
  }

  getFontFamily() {
    this.props.fontFamily = this.getActiveProp('fontFamily');
  }

  setFontFamily() {
    this.setActiveProp('fontFamily', this.props.fontFamily);
  }

  /*System*/


  removeSelected() {
    const activeObject: any = this.canvas.getActiveObject();
    const activeGroup: any = this.canvas.getActiveObjects();

    if (activeGroup) {
      this.canvas.discardActiveObject();
      const self = this;
      activeGroup.forEach((object) => {
        self.canvas.remove(object);
      });
    } else if (activeObject) {
      this.canvas.remove(activeObject);
    }
  }

  bringToFront() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      activeObject.bringToFront();
      activeObject.opacity = 1;
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      activeGroup.forEach((object) => {
        object.bringToFront();
      });
    }
  }

  sendToBack() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      this.canvas.sendToBack(activeObject);
      activeObject.sendToBack();
      activeObject.opacity = 1;
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      activeGroup.forEach((object) => {
        object.sendToBack();
      });
    }
  }

  confirmClear() {
    if (confirm('Are you sure?')) {
        // Stop the animation interval if it's running
        if (this.frameInterval) {
            clearInterval(this.frameInterval);
            this.frameInterval = null; // Clear the interval ID
        }

        // Clear the main canvas
        this.canvas.clear();

        // Optionally reset the currentFrame index
        this.currentFrame = 0;

        // Clear any stored frame data if necessary
        this.frames = []; // Assuming 'frames' holds data URLs or image elements
        this.frameCanvases.forEach(canvas => {
            canvas.dispose(); // Properly dispose of each fabric.Canvas if they won't be reused
        });
        this.frameCanvases = []; // Clear the array of frame canvases

        // Additional UI or state reset if needed
        console.log('All frames and the canvas have been cleared.');
    }
}


  // Function to add a text watermark
  addWatermark() {
    // Create a new text object for the watermark
    const watermarkText = new fabric.Text('mhmes.com', {
      left: this.canvas.getWidth() - 150, // Position: adjust as needed
      top: this.canvas.getHeight() - 20,  // Position: adjust as needed
      fontSize: 14,
      fill: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white
      selectable: false // Make the watermark unselectable
    });
  
    // Add watermark text to the canvas
    this.canvas.add(watermarkText);
    this.canvas.renderAll(); // Re-render the canvas to display changes
  }

  rasterize() {
    this.addWatermark();
    const image = new Image();
    image.src = this.canvas.toDataURL({ format: 'png' });
    const w = window.open('');
    w.document.write(image.outerHTML);
    this.downLoadImage();
  }

  downLoadImage() {
    const c = this.canvas.toDataURL({ format: 'png' });
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = c;
    downloadLink.target = '_self';
    downloadLink.download = Date.now() + '.png';
    downloadLink.click();
  }

  rasterizeSVG() {
    this.addWatermark();
    const w = window.open('');
    w.document.write(this.canvas.toSVG());
    this.downLoadSVG();
    return 'data:image/svg+xml;utf8,' + encodeURIComponent(this.canvas.toSVG());
  }

  downLoadSVG() {
    const c = 'data:image/svg+xml;utf8,' + encodeURIComponent(this.canvas.toSVG());
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = c;
    downloadLink.target = '_self';
    downloadLink.download = Date.now() + '.svg';
    downloadLink.click();
  }

  saveCanvasToJSON() {
    const json = JSON.stringify(this.canvas);
    localStorage.setItem('Kanvas', json);
    console.log('json');
    console.log(json);

  }

  loadCanvasFromJSON() {
    const CANVAS = localStorage.getItem('Kanvas');
    console.log('CANVAS');
    console.log(CANVAS);

    // and load everything from the same json
    this.canvas.loadFromJSON(CANVAS, () => {
      console.log('CANVAS untar');
      console.log(CANVAS);

      // making sure to render canvas at the end
      this.canvas.renderAll();

      // and checking if object's "name" is preserved
      console.log('this.canvas.item(0).name');
      console.log(this.canvas);
    });

  }

  rasterizeJSON() {
    this.json = JSON.stringify(this.canvas, null, 2);
  }

  resetPanels() {
    this.textEditor = false;
    this.imageEditor = false;
    this.figureEditor = false;
  }

  drawingMode() {
    this.canvas.isDrawingMode = !this.canvas.isDrawingMode;
  }

  //----------GIFS---------------



  getFramesFromGif(gifUrl) {
    return fetch(gifUrl)
        .then(response => response.arrayBuffer())
        .then(buffer => {
            const gif = parseGIF(buffer);
            const frames = decompressFrames(gif, true);

            const compositeCanvas = document.createElement('canvas');
            const compCtx = compositeCanvas.getContext('2d');
            const renderedFrames = [];

            frames.forEach((frame, index) => {
                // Resize canvas for the first frame or if necessary
                if (index === 0 || compositeCanvas.width !== frame.dims.width || compositeCanvas.height !== frame.dims.height) {
                    compositeCanvas.width = frame.dims.width;
                    compositeCanvas.height = frame.dims.height;
                }

                // Apply disposal method from previous frame
                if (index > 0 && frames[index - 1].disposalType === 2) {
                    compCtx.clearRect(0, 0, compositeCanvas.width, compositeCanvas.height);
                }

                // Draw current frame
                const frameCanvas = document.createElement('canvas');
                const frameCtx = frameCanvas.getContext('2d');
                frameCanvas.width = frame.dims.width;
                frameCanvas.height = frame.dims.height;
                frameCtx.putImageData(new ImageData(new Uint8ClampedArray(frame.patch), frame.dims.width, frame.dims.height), 0, 0);

                // Draw the frame onto the composite canvas
                compCtx.drawImage(frameCanvas, frame.dims.left, frame.dims.top);

                // Save the composite image
                renderedFrames.push(compositeCanvas.toDataURL());
            });

            return renderedFrames;
        });
  }


applyFrameSettings(): void {
  // Implementation to apply the frame settings
  // This might involve re-initializing the animation with the new frames
  this.startAnimation();
  console.log(`Applying animation from frame ${this.startFrame} to frame ${this.endFrame}`);
  // Your animation function call goes here
}

loadGifFrames(gifUrl: string): void {
  console.log("Loading GIF frames from URL:", gifUrl);
  this.getFramesFromGif(gifUrl).then(frames => {
      console.log("Frames loaded:", frames);
      this.frames = [];
      this.frameCanvases = [];
      let loadedImages = 0;  // Counter for loaded images

      frames.forEach((url, index) => {
          const img = new Image();
          img.onload = () => {
              const canvasElement = document.createElement('canvas');
              canvasElement.width = img.width;
              canvasElement.height = img.height;
              this.size.width = img.width;
              this.size.height = img.height;
              this.canvas.setWidth(img.width);
              this.canvas.setHeight(img.height);

              const container = document.getElementById('hidden-container');
              container.appendChild(canvasElement);

              const frameCanvas = new fabric.Canvas(canvasElement);
              frameCanvas.add(new fabric.Image(img, {
                  selectable: false,
                  evented: false
              }));
              frameCanvas.renderAll();

              this.frames[index] = url;
              this.frameCanvases[index] = frameCanvas;

              loadedImages++;
              if (loadedImages === frames.length) {  // All images have loaded
                  this.endFrame = this.frames.length - 1;  // Now correctly set endFrame
                  console.log(this.endFrame);
                  this.startAnimation();  // Start the animation only after all frames are ready
              }
          };
          img.onerror = (error) => {
              console.error('Error loading image:', error);
          };
          img.src = url;
      });
  }).catch(error => {
      console.error("Error loading frames:", error);
  });
}



startAnimation() {
    if (!this.frameCanvases.length) return;

    if (this.frameInterval) {
      this.stopAnimation();  // Ensure any existing animation is stopped
  }
    
    this.canvas.clear();
    this.currentFrame = this.startFrame; // Start from the startFrame

    this.frameInterval = setInterval(() => {
        var dataUrl = this.frameCanvases[this.currentFrame].toDataURL({format: 'image/png', quality: 1});

        var img = new Image();
        img.onload = () => {
            this.canvas.setBackgroundImage(new fabric.Image(img, {
                originX: 'left',
                originY: 'top',
                scaleX: 1,
                scaleY: 1
            }), this.canvas.renderAll.bind(this.canvas));
        };
        img.src = dataUrl;

        // Increment or reset the frame index based on the frame range
        if (this.currentFrame >= this.endFrame) {
            this.currentFrame = this.startFrame; // Reset to startFrame instead of 0
        } else {
            this.currentFrame++;
        }
    }, this.defaultDelay);
}


stopAnimation(): void {
  if (this.frameInterval) {
    clearInterval(this.frameInterval);  // Stop the interval
    this.frameInterval = null;          // Clear the interval ID stored
    console.log("Animation stopped.");
  }
}


  
changeSpeed(): void {
  clearInterval(this.frameInterval);
  this.frameInterval = null;

  if (this.currentGifObject) { // Check if a GIF is currently being animated
      this.startGifAnimation(this.currentGifObject); // Restart GIF animation with new speed
  } else {
      this.startAnimation(); // Restart image animation with new speed
  }
}


  createGifFromFrames(delay = 100) {
    const gif = new GIF({
        workers: 2,
        quality: 10,
        workerScript: '/assets/gif.worker.js'
    });

    this.addWatermarkGIF();

    const loadFrame = (index) => {
        if (index < this.frameCanvases.length) {
            const dataUrl = this.frameCanvases[index].toDataURL({format: 'image/png', quality: 1}); // Capture the canvas as a PNG
            const img = new Image();
            img.src = dataUrl;
            img.onload = () => {
                gif.addFrame(img, { delay: this.defaultDelay });
                loadFrame(index + 1); // Recursively load the next frame
            };
        } else {
            // All frames are processed, now render the GIF
            gif.on('finished', (blob) => {
                const url = URL.createObjectURL(blob);

                // Open the GIF in a new tab
                window.open(url, '_blank');

                // Optionally, you can also trigger a download
                const a = document.createElement('a');
                a.href = url;
                a.download = 'animated.gif'; // Specify the filename for the download
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                // Clean up the blob URL after use
                URL.revokeObjectURL(url);
            });
            gif.render();
        }
    };

    loadFrame(0); // Start loading frames from the first one
}

addWatermarkGIF() {
  // Loop through each frame canvas
  this.frameCanvases.forEach((frameCanvas) => {
    // Create a new text object for the watermark for each frame
    const watermarkText = new fabric.Text('mhmes.com', {
      left: frameCanvas.getWidth() - 150, // Position adjusted based on the canvas width
      top: frameCanvas.getHeight() - 20, // Position adjusted based on the canvas height
      fontSize: 14,
      fill: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white
      selectable: false // Make the watermark unselectable
    });

    // Add watermark text to each frame canvas
    frameCanvas.add(watermarkText);
    frameCanvas.renderAll(); // Re-render each canvas to display changes
  });
}



cloneToAllFrames() {
  const activeObject = this.canvas.getActiveObject();
  if (activeObject) {
      activeObject.clone((clonedObj) => {
          // Loop only through the specified frame range
          for (let i = this.startFrame; i <= this.endFrame; i++) {
              const frameCanvas = this.frameCanvases[i];
              if (frameCanvas) {  // Check if the frameCanvas exists
                  const cloneForFrame = fabric.util.object.clone(clonedObj);
                  frameCanvas.add(cloneForFrame);
                  frameCanvas.renderAll();
              }
          }
      });
  }
}


startRecording() {
  this.savedObjects = []; // Reset previous records
  this.recordingCompleteUpdated.emit(false);
  this.recordMovement(); // Start recording movement
}

delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


async recordMovement() {
  if (!this.frameCanvases.length || this.isRecording) return;
  this.stopAnimation();
  this.isRecording = true;  // Control variable to manage recording state
  let currentFrame = this.startFrame;

  const processFrame = async () => {
      if (currentFrame > this.endFrame) {
          console.log('Completed recording and displaying all frames.');
          this.recordingCompleteUpdated.emit(true);
          this.isRecording = false;  // Reset the control variable
          return;
      }

      const dataUrl = this.frameCanvases[currentFrame].toDataURL({format: 'image/png', quality: 1});
      const img = new Image();
      img.onload = async () => {
          this.canvas.setBackgroundImage(new fabric.Image(img, {
              originX: 'left',
              originY: 'top',
              scaleX: 1,
              scaleY: 1
          }), async () => {
              this.canvas.renderAll();
              await this.cloneAndSaveObject(currentFrame);
              console.log(`Processed frame ${currentFrame}:`, this.savedObjects);
              currentFrame++;const startTime = performance.now();
  
              await this.delay(this.defaultDelay);
              // After rendering or processing...
              const elapsed = performance.now() - startTime;
              console.log(`Frame processing took ${elapsed} ms.`);
              processFrame();
          });
      };
      img.onerror = async () => {
          console.error(`Failed to load image for frame ${currentFrame}`);
          currentFrame++;
          await this.delay(this.defaultDelay);
          processFrame();
      };
      img.src = dataUrl;
  };

  processFrame();
}



async cloneAndSaveObject(frameIndex: number): Promise<void> {
  const activeObject = this.canvas.getActiveObject();
  if (activeObject) {
      return new Promise<void>((resolve, reject) => { // Explicitly defining the Promise type as void
          activeObject.clone((clonedObj) => {
              clonedObj.frameIndex = frameIndex; // Extending clonedObj with custom properties, such as frame index
              this.savedObjects.push({
                  object: clonedObj,
                  frame: frameIndex
              });
              console.log(`Object and its frame index saved:`, clonedObj);
              resolve(); // Correctly resolving the promise without any value
          });
      });
  } else {
      console.error("No active object to clone and save.");
      return Promise.reject("No active object to clone and save."); // Properly rejecting the promise
  }
}



restoreObjects() {
  // Iterate over all saved objects
  for (let saved of this.savedObjects) {
      const frameCanvas = this.frameCanvases[saved.frame];
      if (frameCanvas) {
          // Add the saved object to its original frame canvas
          frameCanvas.add(saved.object);
          frameCanvas.renderAll();  // Re-render the canvas to display the object
      } else {
          console.error('No canvas available for frame', saved.frame);
      }
  }
}


approveRecording() {
  console.log('Recording approved:', this.savedObjects);
  this.recordingCompleteUpdated.emit(false);

  this.restoreObjects();
  this.startAnimation();
  // Additional logic to handle approved recording
}

startManualEditing() {
  this.stopAnimation(); // Ensure no existing animation is running
  this.savedObjects = new Array(this.frameCanvases.length).fill(null); // Reset previously recorded objects
  this.isManualEditing = true;
  this.currentFrame = 0; // Start with the first frame
  this.loadFrameToCanvas(this.currentFrame); // Load the first frame
}

loadFrameToCanvas(frameIndex) {
  this.canvas.clear();
  const frameCanvas = this.frameCanvases[frameIndex];
  const url = frameCanvas.toDataURL();

  const img = new Image();
  img.onload = () => {
    this.canvas.setBackgroundImage(new fabric.Image(img, {
      originX: 'left',
      originY: 'top',
      scaleX: 1,
      scaleY: 1
    }), this.canvas.renderAll.bind(this.canvas));

    // Restore the previously positioned object for this frame if it exists
    let savedObject = this.savedObjects[frameIndex];

    if (!savedObject && frameIndex > 0) {
      // If there is no saved object for this frame, clone the previous frame's object
      const previousObject = this.savedObjects[frameIndex - 1];
      if (previousObject) {
        previousObject.clone((clonedObj) => {
          this.canvas.add(clonedObj);
          this.canvas.setActiveObject(clonedObj);
        });
      }
    } else if (savedObject) {
      // If a saved object exists for this frame, use it
      this.canvas.add(savedObject);
      this.canvas.setActiveObject(savedObject);
    }
  };
  img.src = url;
}


async saveCurrentObjectState(): Promise<void> {
  const activeObject = this.canvas.getActiveObject();
  if (activeObject) {
    return new Promise<void>((resolve, reject) => {
      activeObject.clone((clonedObj) => {
        this.savedObjects[this.currentFrame] = clonedObj; // Save or update the object state for the current frame
        console.log(`Object saved for frame ${this.currentFrame}:`, clonedObj);
        resolve(); // Resolve the promise once the object is saved
      });
    });
  } else {
    console.log("No active object to save.");
    return Promise.resolve(); // Resolve immediately if no active object
  }
}

async nextFrame() {
  if (this.currentFrame < this.endFrame) {
    console.log(this.currentFrame);
    await this.saveCurrentObjectState();
    this.currentFrame++;
    this.loadFrameToCanvas(this.currentFrame);
  } else {
    console.log("Reached the last frame.");
    this.approveEdits();
  }
}


async previousFrame() {
  if (this.currentFrame > this.startFrame) {
    await this.saveCurrentObjectState();
    this.currentFrame--;
    this.loadFrameToCanvas(this.currentFrame);
  } else {
    console.log("Already at the first frame.");
  }
}


async approveEdits() {
  await this.saveCurrentObjectState(); // Ensure the last edited frame is saved
  await this.addObjects(); // Add objects to their specific frames in the Fabric canvases
  this.isManualEditing = false;
  console.log('Edits approved:', this.savedObjects);
  this.startAnimation(); // Restart the animation with the new object positions
}


async addObjects(): Promise<void> {
  // Iterate over savedObjects and corresponding frameCanvases
  for (let index = 0; index < this.savedObjects.length; index++) {
    const savedObject = this.savedObjects[index];

    // Check if there's an object saved for this frame
    if (savedObject) {
      console.log(`Processing frame index: ${index}`);
      const frameCanvas = this.frameCanvases[index];

      if (frameCanvas) {
        await new Promise<void>((resolve) => {
          // Add the saved object to its original frame canvas
          frameCanvas.add(savedObject);
          frameCanvas.renderAll(); // Re-render the canvas to display the object
          console.log(`Added object to frame ${index}`);
          resolve();
        });
      } else {
        console.error('No canvas available for frame', index);
      }
    }
  }
}



// Function to add a GIF to the canvas
addGifOnCanvas(url: string) {
  this.getFramesFromGif(url).then(frames => {
      if (!frames.length) return;

      const gifObject = {
          frames: [],
          currentFrame: 0,
          interval: null,
          size: { width: 200, height: 200 },
          position: { left: 10, top: 10 },
          angle: 0
      };

      frames.forEach((frameUrl, index) => {
          const img = new Image();
          img.onload = () => {
              const fabricImg = new fabric.Image(img, {
                  selectable: index === 0,  // Only the first frame is selectable
                  evented: index === 0,
                  opacity: index === 0 ? 0 : 1  // First frame is initially invisible
              });

              gifObject.frames.push(fabricImg);

              // Start animation after all frames are loaded
              if (gifObject.frames.length === frames.length) {
                  this.canvas.add(gifObject.frames[0]); // Add the first frame to the canvas
                  gifObject.frames[0].set('opacity', 1); // Make the first frame visible
                  this.canvas.setActiveObject(gifObject.frames[0]); // Make the first frame active
                  this.currentGifObject = gifObject;
                  this.startGifAnimation(gifObject);
                  this.setupFrameChangeListener(gifObject);
              }
          };
          img.src = frameUrl;
      });
  }).catch(error => {
      console.error("Error loading GIF frames:", error);
  });
}

// Function to start the GIF animation on the canvas
startGifAnimation(gifObject) {
  const addFrameToCanvas = () => {
      // Ensure the last object is a fabric.Object and remove it if it exists
      const lastIndex = this.canvas.getObjects().length - 1;
      if (lastIndex >= 0) {
          const lastObject = this.canvas.item(lastIndex);
          if (lastObject && lastObject !== gifObject.frames[0]) {  // Do not remove the first frame
              this.canvas.remove(lastObject as unknown as fabric.Object);
          }
      }

      // Get the current frame and apply properties from the first frame
      const firstFrame = gifObject.frames[0];
      const frame = gifObject.frames[gifObject.currentFrame];

      frame.set({
          left: firstFrame.left,
          top: firstFrame.top,
          angle: firstFrame.angle,
          scaleX: firstFrame.scaleX,
          scaleY: firstFrame.scaleY,
          originX: firstFrame.originX,
          originY: firstFrame.originY,
          opacity: 1,
          padding: 10,
          cornerSize: 10,
          hasRotatingPoint: true
      });

      if (gifObject.currentFrame !== 0) { // Skip the first frame since it's already on the canvas
          this.canvas.add(frame);
      }

      // Update the visibility of the first frame
      firstFrame.set('opacity', gifObject.currentFrame === 0 ? 1 : 0);

      // Update the current frame index
      gifObject.currentFrame = (gifObject.currentFrame + 1) % gifObject.frames.length;
      this.canvas.renderAll();
  };

  // Clear any existing interval before starting a new one
  if (gifObject.interval) {
      clearInterval(gifObject.interval);
  }

  // Start the interval to add frames to the canvas
  gifObject.interval = setInterval(addFrameToCanvas, this.defaultDelay);
}

// Function to stop the GIF animation
stopGifAnimation(gifObject) {
  if (gifObject.interval) {
      clearInterval(gifObject.interval);
      gifObject.interval = null;
  }
}

// Function to reset the GIF animation
resetGifAnimation(gifObject) {
  this.stopGifAnimation(gifObject);
  gifObject.currentFrame = 0;
  this.startGifAnimation(gifObject);
}

// Function to set up listener for changes to the first frame
setupFrameChangeListener(gifObject) {
  const firstFrame = gifObject.frames[0];

  // Listen for changes to the first frame and apply them to the gifObject
  firstFrame.on('modified', () => {
      gifObject.size.width = firstFrame.getScaledWidth();
      gifObject.size.height = firstFrame.getScaledHeight();
      gifObject.position.left = firstFrame.left;
      gifObject.position.top = firstFrame.top;
      gifObject.angle = firstFrame.angle;

      // Apply changes to all frames
      gifObject.frames.forEach((frame, index) => {
          if (index !== 0) {
              frame.set({
                  left: gifObject.position.left,
                  top: gifObject.position.top,
                  angle: gifObject.angle,
                  scaleX: gifObject.size.width / frame.width,
                  scaleY: gifObject.size.height / frame.height
              });
          }
      });
  });
}





}
