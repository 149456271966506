import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FabricjsEditorComponent } from 'projects/angular-editor-fabric-js/src/public-api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Mhmes';

  constructor(private cd: ChangeDetectorRef) {}

  @ViewChild('canvas', { static: false }) canvas: FabricjsEditorComponent;
  public templateImages: string[] = [];
  public overlayImages: string[] = [];
  public isSelected: boolean = false;
  public recordingComplete: boolean = false;
  public recordingPressed: boolean = false;
  public countdown = 5;

  onTemplateImagesUpdated(images: string[]): void {
    this.templateImages = images;
    console.log(this.templateImages);
  }

  onOverlayImagesUpdated(images: string[]): void {
    this.overlayImages = images;
    console.log(this.overlayImages);
  }

  public rasterize() {
    this.canvas.rasterize();
  }

  public rasterizeSVG() {
    this.canvas.rasterizeSVG();
  }

  public saveCanvasToJSON() {
    this.canvas.saveCanvasToJSON();
  }

  public loadCanvasFromJSON() {
    this.canvas.loadCanvasFromJSON();
  }

  public confirmClear() {
    this.canvas.confirmClear();
  }

  public changeSize() {
    this.canvas.changeSize();
  }

  public addText() {
    this.canvas.addText();
  }

  public getImgPolaroid(event) {
    this.canvas.getImgPolaroid(event);
  }

  public addImageOnCanvas(url) {
    this.canvas.addImageOnCanvas(url);
  }

  public readUrl(event) {
    this.canvas.readUrl(event);
  }

  public removeWhite(url) {
    this.canvas.removeWhite(url);
  }

  public addFigure(figure) {
    this.canvas.addFigure(figure);
  }

  public removeSelected() {
    this.canvas.removeSelected();
  }

  public sendToBack() {
    this.canvas.sendToBack();
  }

  public bringToFront() {
    this.canvas.bringToFront();
  }

  public clone() {
    this.canvas.clone();
  }

  public cleanSelect() {
    this.canvas.cleanSelect();
  }

  public setCanvasFill() {
    this.canvas.setCanvasFill();
  }

  public setCanvasImage() {
    this.canvas.setCanvasImage();
  }

  public setId() {
    this.canvas.setId();
  }

  public setOpacity() {
    this.canvas.setOpacity();
  }

  public setFill() {
    this.canvas.setFill();
  }

  public setFontFamily() {
    this.canvas.setFontFamily();
  }

  public setTextAlign(value) {
    this.canvas.setTextAlign(value);
  }

  public setBold() {
    this.canvas.setBold();
  }

  public setFontStyle() {
    this.canvas.setFontStyle();
  }

  public hasTextDecoration(value) {
    this.canvas.hasTextDecoration(value);
  }

  public setTextDecoration(value) {
    this.canvas.setTextDecoration(value);
  }

  public setFontSize() {
    this.canvas.setFontSize();
  }

  public setLineHeight() {
    this.canvas.setLineHeight();
  }

  public setCharSpacing() {
    this.canvas.setCharSpacing();
  }

  public rasterizeJSON() {
    this.canvas.rasterizeJSON();
  }

  public drawMode() {
    this.canvas.drawingMode();
  }

  public changeFigureColor(color){
    this.canvas.changeFigureColor(color);
  }

  onObjectColorChange(e){
    console.log(e)
    this.changeFigureColor(e.target.value)
  }
  public uploadBackgroundImage(event) {
    this.canvas.uploadBackgroundImage(event);
  }
  public SetCanvasBackground(url) {
    this.canvas.setCanvasBackground(url);
  }
  public loadImages(container) {
    this.loadImages(container);
  }

  public download_gif() {
    this.canvas.createGifFromFrames();
  }

  public changeSpeed() {
    this.canvas.changeSpeed();
  }

  public applyFrameSettings() {
    this.canvas.applyFrameSettings();
  }

  public add_objects_gif() {
    this.canvas.cloneToAllFrames();
  }

  public prepareRecording() {
    this.recordingPressed = true;
    this.countdown = 5;
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.start_recording();
      }
    }, 1000);
  }

  public start_recording() {
    this.canvas.startRecording();
  }

  public approveRecording() {
    this.recordingPressed = false;
    this.recordingComplete = false;
    this.canvas.approveRecording();
  }

  public retryRecording() {
    this.recordingComplete = false;
    this.prepareRecording();
  }

  onrecordingComplete(value: boolean): void {
    this.recordingComplete = value;
    this.cd.detectChanges(); // Force update detection
    console.log(this.recordingComplete);
  }

  public addMemeText() {
    this.canvas.addMemeText();
  }

  public startManualEditing(){
    this.canvas.startManualEditing();
  }

  public nextFrame(){
    this.canvas.nextFrame();
  }

  public previousFrame(){
    this.canvas.previousFrame();
  }

  public approveEdits(){
    this.canvas.approveEdits();
  }

}
