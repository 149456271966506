<div class="container">
    <!-- Header row with logo and navigation menu -->
    <div class="row">
        <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <a class="navbar-brand" href="#">
                    <img src="/assets/img/mhmeslogo.png" width="250" height="70" alt="Logo">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">Creator <span class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Dashboard (COMING SOON)</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://docs.google.com/forms/d/e/1FAIpQLSezjEXw98tqvrqv4GGIdKsSpWTPovNqnVT9jggAHWMOXyx1fw/viewform?usp=sf_link">Feedback</a>
                        </li>
                    </ul>
                </div>
            </nav>
            
        </div>
    </div>
    <!-- Donation Banner Row -->
    <div class="row">
        <div class="col-12">
            <div class="alert alert-success text-center" role="alert">
                <strong>Keep the service alive!</strong> Donate Solana to: CCV8Qgqm6xP2FfmeMgxrg35HtJAAYzx1KVQhTXBiKAuZ or Ethereum to: 0xB3a5C26C23430fEf8748BB8E046383417bCB2c0F
            </div>
        </div>
    </div>
    <br>      
    <div class="row" id="first-row">
        <!-- Content for the first row -->
        <!-- Background Gallery -->
        <div class="col-lg-6">
            <div class="template-gallery">
                <h3>Background Gallery</h3>
                <div class="gallery-container">
                    <div class="row">
                        <div *ngFor="let image of templateImages" class="col-12 mb-2">
                            <div class="img-thumbnail">
                                <img [src]="image.url" class="img-fluid" (click)="SetCanvasBackground(image.url)" alt="Thumbnail">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Background Image Upload -->
        <div class="col-lg-3">
            <div class="custom-item-title">Background Upload (supports all static image files and only .gif)</div>
            <div class="custom-item-body">
                <img *ngIf="canvas.backgroundImage" [src]="canvas.backgroundImage" class="images-item-upload">
                <input type="file" (change)="uploadBackgroundImage($event)">
                <br><br>
            </div>
        </div>
        <!-- Canvas Size Controls -->
        <div class="col-lg-3">
            <div class="card">
                <div class="card-header">Canvas Size</div>
                <div class="card-body">
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="canvas.size.width" (keyup)="changeSize()">
                            <div class="input-group-append">
                                <span class="input-group-text">Width</span>
                            </div>
                        </div>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="canvas.size.height" (keyup)="changeSize()">
                            <div class="input-group-append">
                                <span class="input-group-text">Height</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row" id="second-row">
        <!-- Content for the second row -->
        <!-- Overlay Image Gallery -->
        <div class="col-lg-6">
            <div class="overlay-gallery">
                <h3>Overlay Image Gallery</h3>
                <div class="gallery-container">
                    <div class="row">
                        <div *ngFor="let image of overlayImages" class="col-12 mb-2">
                            <div class="img-thumbnail">
                                <img [src]="image.url" class="img-fluid" (click)="addImageOnCanvas(image.url)" alt="Thumbnail">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Image Upload -->
        <div class="col-lg-3">
            <div class="custom-item-body">
                <div class="custom-item-title">Upload Overlay Image (supports all static image files)</div>
                    <img id="testImage" *ngIf="canvas.url" class="images-item-upload" [src]="canvas.url" (click)="addImageOnCanvas(canvas.url);">
                    <input type="file" (change)="readUrl($event);">
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card" *ngIf="!canvas.selected">
                <div class="card-header">Canvas Color</div>
                <div class="card-body">
                    <div class="custom-item" *ngIf="!canvas.props.canvasImage">
                        <div class="custom-item-title">Background Color</div>
                        <div class="custom-item-body">
                            <input type="text" class="form-control" [cpPosition]="'bottom'" [(colorPicker)]="canvas.props.canvasFill" [style.background]="canvas.props.canvasFill"
                                [value]="canvas.props.canvasFill" (colorPickerChange)="setCanvasFill()">
                        </div>
                    </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row" id="fourth-row">
        <!-- Content for the fourth row -->
        <div class="col-lg-3">
            <!-- Select Options  -->
            <div class="card">
                <div class="card-header">Options for Overlay</div>
                <div class="card-body text-center">
                    <div class="btn-group" role="group" aria-label="...">
                        <button type="button" class="btn btn-outline-danger" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete element" (click)="removeSelected();">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>                          
                        <button type="button" class="btn btn-outline-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Send to back" (click)="sendToBack();">
                            <i class="fa fa-level-down" aria-hidden="true"></i>
                        </button>
                        <button type="button" class="btn btn-outline-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Send to front" (click)="bringToFront();">
                            <i class="fa fa-level-up" aria-hidden="true"></i>
                        </button>
                        <button type="button" class="btn btn-outline-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Clone" (click)="clone();">
                            <i class="fa fa-clone" aria-hidden="true"></i>
                        </button>
                        <button type="button" class="btn btn-outline-primary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Unselect" (click)="cleanSelect();">
                            <i class="fa fa-remove" aria-hidden="true"></i>
                        </button>                        
                    </div>
                </div>
            </div>
            <!-- Drawing -->
            <div class="card">
                <div class="card-header">Tools</div>
                <div class="card-body">
                    <div class="input-group mb-3">
                        <input type="range" class="custom-range" min="5" max="5000" step="2" value="100" [(ngModel)]="canvas.defaultDelay" (change)="changeSpeed()">
                        <label class="input-group-text">Gif Speed Adjustment</label>
                    </div>
                    <br>
                    <div class="input-group">
                        <input type="range" class="form-control-range" id="startFrameRange" [(ngModel)]="canvas.startFrame" (change)="applyFrameSettings()" min="0" max="{{ canvas.frames.length - 1 }}">
                        <div class="input-group-append">
                          <span class="input-group-text">Start Frame: {{ canvas.startFrame }}</span>
                        </div>
                      </div>
                      
                      <div class="input-group">
                        <input type="range" class="form-control-range" id="endFrameRange" [(ngModel)]="canvas.endFrame" (change)="applyFrameSettings()" min="0" max="{{ canvas.frames.length - 1 }}">
                        <div class="input-group-append">
                          <span class="input-group-text">End Frame: {{ canvas.endFrame }}</span>
                        </div>
                      </div>                                           
                    <br>
                    <div class="gif-add-objects-fixed">
                        <button class="btn btn-primary" id="addObjectsToGifButton" (click)="add_objects_gif()">
                            Add objects to GIF
                        </button>
                    </div>   
                    <br>                 
                    <div class="gif-add-objects-fixed">
                        <button class="btn btn-primary" id="addObjectsToGifButton" (click)="prepareRecording()">
                            Record Movement of object
                        </button>
                    </div>
                    <!-- Countdown Display -->
                    <div *ngIf="recordingPressed">
                        <h2>Recording starts in: {{ countdown }}</h2>
                    </div>
                    <br>
                    <!-- Retry and Approve buttons -->
                    <div *ngIf="recordingComplete">
                        <button class="btn btn-success" (click)="approveRecording()">Approve</button>
                        <button class="btn btn-warning" (click)="retryRecording()">Try Again</button>
                    </div>
                    <!-- Manual Overlay Addition -->
                    <div class="gif-manual-overlay">
                        <button class="btn btn-secondary" id="addOverlayManuallyButton" (click)="startManualEditing()">
                            Add Object Manually to Each Frame
                        </button>
                        <br>
                        <br>
                        <div *ngIf="canvas.isManualEditing">
                            <button class="btn btn-info" (click)="previousFrame()">Previous Frame</button>
                            <button class="btn btn-info" (click)="nextFrame()">Next Frame</button>
                            <button class="btn btn-success" (click)="approveEdits()">Approve Edits</button>
                        </div>
                    </div>
                    <!-- Free drawing -->
                    <div class="input-group">
                        <div class="input-group-append">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="customSwitch1" (click)="drawMode()">
                                <label class="custom-control-label" for="customSwitch1">Free Drawing</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <!-- Add text feature -->
            <div class="card">
                <div class="card-header">Add text</div>
                <div class="card-body">
                    <div class="input-group">
                        <input type="text" class="form-control" [(ngModel)]="canvas.textString">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <button id="add-text" data-toggle="tooltip" data-placement="bottom" title="Add text" class="btn btn-primary" (click)="addText()">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                    <br>
                    <button class="btn btn-primary" (click)="addMemeText()">Add Meme Text</button>
                </div>
            </div>
            <!-- Add figure feature -->
            <div class="card">
                <div class="card-header">Add figure</div>
                <div class="card-body text-center max-height">
                    <div class="btn-group btn-group-vertical" role="group" aria-label="...">
                        <button type="button" class="btn btn-primary" (click)="addFigure('rectangle');">Rectangle</button>
                        <button type="button" class="btn btn-primary" (click)="addFigure('square');">Square</button>
                        <button type="button" class="btn btn-primary" (click)="addFigure('triangle');">Triangle</button>
                        <button type="button" class="btn btn-primary" (click)="addFigure('circle');">Circle</button>
                    </div>
                </div>
            </div>
            <!-- Custom Controls. Not in use currently -->
            <div class="card">
                <div class="card-header">Custom</div>
                <div class="card-body">
                    <div class="custom-item" *ngIf="canvas.selected  && canvas.selected.type == 'group'">Group Selected</div>
                    <div class="custom-item" *ngIf="!canvas.selected">No items selected</div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.selected.type != 'group'">
                        <div class="custom-item-title">ID</div>
                        <div class="custom-item-body">
                            <input type="text" class="form-control" [(ngModel)]="canvas.props.id" (keyup)="setId()">
                        </div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.selected.type != 'group'">
                        <div class="custom-item-title">Opacity</div>
                        <div class="custom-item-body">
                            <input type="range" [(ngModel)]="canvas.props.opacity" (change)="setOpacity()">{{canvas.props.opacity}}</div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor || canvas.selected && canvas.figureEditor">
                        <div class="custom-item-title">Fill</div>
                        <div class="custom-item-body">
                            <input type="text" class="form-control" [cpPosition]="'bottom'" [(colorPicker)]="canvas.props.fill" [style.background]="canvas.props.fill"
                                [value]="canvas.props.fill" (colorPickerChange)="setFill()">
                        </div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Font family</div>
                        <div class="custom-item-body">
                            <select [(ngModel)]="canvas.props.fontFamily" class="form-control" (change)="setFontFamily()">
                                <option value="arial">Arial</option>
                                <option value="helvetica" selected>Helvetica</option>
                                <option value="verdana">Verdana</option>
                                <option value="courier">Courier</option>
                                <option value="Roboto">Roboto</option>
                                <option value="Open Sans">Open Sans</option>
                                <option value="Zilla Slab">Zilla Slab</option>
                                <option value="Lato">Lato</option>
                                <option value="Bellefair">Bellefair</option>
                                <option value="Fresca">Fresca</option>
                                <option value="Raleway">Raleway</option>
                                <option value="Open Sans Condensed">Open Sans Condensed</option>
                                <option value="Indie Flower">Indie Flower</option>
                                <option value="Josefin Sans">Josefin Sans</option>
                                <option value="Inconsolata">Inconsolata</option>
                                <option value="Pacifico">Pacifico</option>
                                <option value="Gloria Hallelujah">Gloria Hallelujah</option>
                            </select>
                        </div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Text Align</div>
                        <div class="custom-item-body text-center">
                            <div class="btn-group" role="group" aria-label="...">
                                <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'left' }" (click)="setTextAlign('left')">
                                    <i class="fa fa-align-left"></i>
                                </button>
                                <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'center' }" (click)="setTextAlign('center')">
                                    <i class="fa fa-align-center"></i>
                                </button>
                                <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'right' }" (click)="setTextAlign('right')">
                                    <i class="fa fa-align-right"></i>
                                </button>
                                <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'justify' }" (click)="setTextAlign('justify')">
                                    <i class="fa fa-align-justify"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Style</div>
                        <div class="custom-item-body text-center">
                            <div class="btn-group" role="group" aria-label="...">
                                <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': canvas.props.fontWeight }" (click)="setBold()">
                                    <i class="fa fa-bold"></i>
                                </button>
                                <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': canvas.props.fontStyle }" (click)="setFontStyle()">
                                    <i class="fa fa-italic"></i>
                                </button>
                                <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': hasTextDecoration('underline') }"
                                    (click)="setTextDecoration('underline')">
                                    <i class="fa fa-underline"></i>
                                </button>
                                <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': hasTextDecoration('overline') }"
                                    (click)="setTextDecoration('overline')">
                                    <i class="fa fa-underline fa-flip-vertical"></i>
                                </button>
                                <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': hasTextDecoration('line-through') }"
                                    (click)="setTextDecoration('line-through')">
                                    <i class="fa fa-strikethrough"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Font Size</div>
                        <div class="custom-item-body">
                            <input type="range" [(ngModel)]="canvas.props.fontSize" (change)="setFontSize()" step="1" min="1" max="120">{{canvas.props.fontSize}}</div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Line Height</div>
                        <div class="custom-item-body">
                            <input type="range" [(ngModel)]="canvas.props.lineHeight" (change)="setLineHeight()" step="0.1" min="0" max="10">{{canvas.props.lineHeight}}</div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Char Spacing</div>
                        <div class="custom-item-body">
                            <input type="range" [(ngModel)]="canvas.props.charSpacing" (change)="setCharSpacing()" step="10" min="-200" max="800">{{canvas.props.charSpacing}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9 canvas-container">
            <button data-toggle="tooltip" data-placement="bottom" title="Export to image" type="button" class="btn btn-primary m-1" (click)="rasterize();">
                <i class="fa fa-download" aria-hidden="true"></i> Export to image</button>
            <button data-toggle="tooltip" data-placement="bottom" title="Export to SVG" type="button" class="btn btn-primary m-1" (click)="rasterizeSVG();">
                <i class="fa fa-download" aria-hidden="true"></i> Export to SVG</button>
            <button data-toggle="tooltip" data-placement="bottom" title="Export as gif" type="button" class="btn btn-primary m-1" (click)="download_gif();">
                <i class="fa fa-download" aria-hidden="true"></i> Export as gif</button>
            <button data-toggle="tooltip" data-placement="bottom" title="Clean Canvas" type="button" class="btn btn-danger" (click)="confirmClear();">
                <i class="fa fa-ban" aria-hidden="true"></i> Clean</button>
            <angular-editor-fabric-js #canvas
            (imagesTemplateUpdated)="onTemplateImagesUpdated($event)"
            (imagesOverlayUpdated)="onOverlayImagesUpdated($event)"
            (recordingCompleteUpdated)="onrecordingComplete($event)">
            </angular-editor-fabric-js>
        </div>
        <br>
    <br>
    </div>
    <div id="hidden-container" style="display: none;"></div>
    <div>
        <router-outlet></router-outlet>
    </div>
    <!-- Footer -->
    <div class="row">
        <div class="col-12">
            <footer class="bg-light text-center text-lg-start">
                <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.05);">
                    © 2024 mhmes:
                    <a class="text-dark" href="https://mhmes.com/">mhmes.com</a>
                    <br> 
                    <a class="text-dark" href="https://twitter.com/mhmescom">Click here to check out our X account for "How to" and inspiration!</a>
                    <br>
                    <a class="text-dark" [routerLink]="['/terms-and-conditions']">Terms and Conditions</a>
                </div>
            </footer>
        </div>
    </div>
</div>
  